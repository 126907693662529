import * as React from "react";
import { Link, HeadFC } from "gatsby";

const NotFoundPage = () => {
  return (
    <>
      Page Not Found. <br />
      Try starting on the <a href="/">homepage</a>
    </>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
